import React, { ReactNode, useState } from 'react';
import { Flex, Icon, Box, SystemProps } from '@storyofams/react-ui';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '../Button';
import { Divider } from '../Divider';
import { Heading } from '../Heading';
import { Plus, ChevronDown } from '../Icons';

interface AccordionProps extends SystemProps {
  title: string;
  children: ReactNode;
  textProps?: object;
  useBottomDivider?: boolean;
  dividerColor?: string;
  icon?: 'chevron' | 'plus';
}

export const Accordion = ({
  title,
  children,
  icon = 'chevron',
  textProps,
  useBottomDivider,
  dividerColor,
  ...props
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!useBottomDivider &&  <Divider color={dividerColor} />}
      <Flex justifyContent="space-between">
        <Button
          variant="unstyled"
          justifyContent="space-between"
          py={3}
          onClick={() => setIsOpen(!isOpen)}
          width="100%"
          alignItems="center"
          pr={1.5}
          {...props}
        >
          <Heading
            variant="sh3"
            as="h2"
            {...textProps}
          >
            {title}
          </Heading>{' '}
          <Icon
            icon={icon === 'plus' ? Plus : ChevronDown}
            fontSize={icon === 'plus' ? 3 : 1.5}
            transform={`rotate(${
              isOpen ? (icon === 'plus' ? '-45' : '-180') : 0
            }deg)`}
            transition={`transform ${icon === 'plus' ? 0.1 : 0.2}s ease-in-out`}
          />
        </Button>
      </Flex>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            exit={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: '100%' }}
            transition={{ ease: 'easeInOut', duration: 0.24 }}
          >
            <Box pb={props.py || props.pb || 3}>{children}</Box>
          </motion.div>
        )}
      </AnimatePresence>
      {useBottomDivider &&  <Divider color={dividerColor} />}
    </>
  );
};
