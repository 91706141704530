import { FC } from "react";
import { Stack, Box, css, SystemProps } from "@storyofams/react-ui";

import {
  render,
  MARK_BOLD,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_OL,
  NODE_UL,
  NODE_LI,
} from "storyblok-rich-text-react-renderer";
import { Heading } from "../Heading";
import { Text } from "../Text";

interface RichTextProps extends SystemProps {
  text: any;
  markResolvers?: any;
  nodeResolvers?: any;
}

export const RichText: FC<RichTextProps> = ({
  markResolvers = {},
  nodeResolvers = {},
  text,
  ...props
}) => (
  <Box color="grey800" css={css({ "> *:last-child": { pb: 0 } })} {...props}>
    {render(text, {
      markResolvers: {
        [MARK_BOLD]: (children) => <b>{children}</b>,
        [MARK_LINK]: (children, props) => {
          const { href } = props;

          return (
            <Box textDecoration="underline" as="span">
              <a
                href={`${props.linktype === "email" ? "mailto:" : ""}${href}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {children}
              </a>
            </Box>
          );
        },
        ...markResolvers,
      },
      nodeResolvers: {
        [NODE_HEADING]: (children, { level }) => (
          <Heading
            as={`h${level}` as any}
            mb={[2, 5]}
            variant={`h${level}` as any}
            color="#21857F"
          >
            {children}
          </Heading>
        ),
        [NODE_PARAGRAPH]: (children) => (
          <Text pb={[2, 3]} as="p">
            {children}
          </Text>
        ),
        [NODE_OL]: (children) => (
          <Stack
            space={[1, 2]}
            // @ts-ignore
            as="ol"
            pl={2}
            pb={[2, 3]}
            flexDirection="column"
          >
            {children}
          </Stack>
        ),
        [NODE_UL]: (children) => (
          <Stack
            space={[1, 2]}
            // @ts-ignore
            as="ul"
            pl={2}
            pb={[2, 3]}
            flexDirection="column"
          >
            {children}
          </Stack>
        ),
        [NODE_LI]: (children) => (
          <Text as={"li" as any} css={css({ p: { pb: 0 } })}>
            {children}
          </Text>
        ),
        ...nodeResolvers,
      },
    })}
  </Box>
);
